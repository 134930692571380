import { IoMdClose } from "react-icons/io";

const ModalWindow = ({content, title, width, showCloseButton, closeFunction}) => {
    return(
        <div className='modalBackground'>
            <div className="modalWindow" style={{paddingBottom:"5px", width:width}}>
                <div className='modalTitleBar'>
                    <div className="modalTitle">{title}</div>
                    { showCloseButton &&
                        <div className="modalCloseBtn">
                            <IoMdClose onClick={() => {closeFunction()}} size="2.5vh"/>
                        </div>
                    }
                </div>
                <div style={{margin:"15px"}}>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default ModalWindow;