import axios from "axios";
import { Cookies } from "react-cookie";
import { AddInfoBoxMessage } from "../InfoBoxManager";

let api_url = process.env.REACT_APP_API_URL + "api/AdminPanel/Auth/";
const cookies = new Cookies();

const LoginFirstStep = (loginOrEmail, password, setCodeId, setLoginStep, setError) =>
{
    let loginData = {
        emailOrLogin: loginOrEmail,
        password: password
    };

    axios.post(api_url + "Login", loginData)
    .then((res) => 
    {
        if(res.data != 0)
        {
            setError("");
            setCodeId(res.data);
            setLoginStep(1);
        }else
        {
            setError("Wrong password or login");
        }
    })
    .catch((reason) => { setError(reason.message)});
}

const LoginSecondStep = (code, codeId, navigate, setError) =>
    {
        let loginData = {
            CodeId: codeId,
            Code: code
        };
    
        axios.post(api_url + "Login2FA", loginData)
        .then((res) => 
        {   
            if(!res.data.error)
            {
                cookies.set("jwt", res.data.jwt, { maxAge: 600, path: '/', secure: true, sameSite: "strict" });
    
                cookies.set("refreshToken", res.data.refreshToken, {maxAge: 2147483647, path: '/', secure: true, sameSite: "strict"});
                navigate("/servers");
            }else{
                setError(res.data.error)
            }
        })
        .catch((reason) => { setError(reason.message)});
    }

const RefreshToken = (changeSiteOnSuccess, navigate, reloadWindow) =>
{
    let token = cookies.get("refreshToken");
    
    if(token)
    {      
        axios.post(api_url + "RefreshJWT", {refreshToken: token})
        .then((res) => 
        {

            cookies.set("jwt", res.data, {maxAge: 600, path: '/', secure: true, sameSite: "strict"});

            //Remove tokens on error
            if(!res.data) {
                cookies.remove("refreshToken");
                cookies.remove("jwt");
                navigate("/");
            }

            if(changeSiteOnSuccess)
                navigate("/servers")

            if(reloadWindow)
                window.location.reload();

        }).catch((error) => 
        {
            console.error(error);
            AddInfoBoxMessage(error.message + ` [${error.config.url}]`, 0);
        });
    }else {
        cookies.remove("refreshToken");
        cookies.remove("jwt");

        if(window.location.pathname != "/")
            navigate("/");
    }
}

const ChangePassword = (data, navigate, setChangePasswordWindow) =>
{
    axios.post(api_url + "ChangePassword", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        AddInfoBoxMessage("Password changed successfully!", 1);
        cookies.remove("refreshToken");
        cookies.remove("jwt");
        setChangePasswordWindow(false);
        navigate("/")
    }).catch((err) => 
    {
        AddInfoBoxMessage(err.message);
        console.error(err);
    });
}

const GetSessions = (setSessions) =>
{
    axios.get(api_url + "GetSessions", {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        setSessions(res.data);
    }).catch((err) => 
    {
        console.error(err);
        //navigate("/")
    });
}

const Logout = (id, navigate, shouldNavigate, func) => 
{
    axios.post(api_url + "Logout/"+id, null, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        func();
        
        // if(shouldNavigate)
        //     navigate("/");

    }).catch((err) => 
    {
        console.error(err);
        // navigate("/");
    });
}
    


export { RefreshToken, ChangePassword, LoginFirstStep, LoginSecondStep, GetSessions, Logout };