import { useNavigate } from "react-router-dom";
import "./ServerGroupPanel.css";
import ModalWindow from "../../../Elements/ModalWindow/ModalWindow";
import { RemoveServerGroup } from "../../../../Scripts/Requests/ServerGroupsRequests";
import { useState } from "react";
import { ServerTypes } from "../../../../Data/ServerTypes";

const ServerGroupPanel = ({serverGroup, SetServerGroups}) => {
    const navigate = useNavigate();

    const [removeWindow, setRemoveWindow] = useState(false);

    const ManageGroup = () => {
        navigate("/servergroup/" + serverGroup.id);
    }

    return (
        <div className="listWidget serverGroupElement">
            <div>
                <h3>{serverGroup.name}</h3>
                <p>{ServerTypes.find(x => x.id == serverGroup.serverType).name}</p>
                <button onClick={() => ManageGroup()}>Manage Group</button>
                <button onClick={() => setRemoveWindow(true)}>Remove Group</button>
            </div>
            { removeWindow &&
                <ModalWindow width={"50%"} title={"Remove Server Group"}
                    content={
                        <>
                            <p>This action can't be undone</p>
                            <div style={{display:"flex"}}>
                                <button style={{width:"50%"}} onClick={() => { RemoveServerGroup(serverGroup.id, SetServerGroups, navigate); setRemoveWindow(false) }}>Remove</button>
                                <button style={{width:"50%"}} onClick={() => { setRemoveWindow(false) }}>Cancel</button>
                            </div>
                        </>
                    }
                />
            }
        </div> 
    )
}

export default ServerGroupPanel;