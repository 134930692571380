import { useEffect, useState } from "react";
import "../Administration/Administration.css";
import "./StaffChat.css";
import { GetStaffChat, ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests";
import { useNavigate, useParams } from "react-router-dom";
import StaffChatMessage from "./Elements/StaffChatMessage";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";

const StaffChat = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    let userData = {};
    const cookies = new Cookies();

    try {
        userData = jwtDecode(cookies.get("jwt"));
    } catch{
        navigate("/");
    }

    const[message, setMessage] = useState("");
    const [staffChat, SetStaffChat] = useState([]);

    useEffect(() => {
        GetStaffChat(id, SetStaffChat, navigate);
  
        clearInterval(() => GetStaffChat(id, SetStaffChat, navigate));
        setInterval(() => {
            GetStaffChat(id, SetStaffChat, navigate);
        }, 10000)
    }, []);

    const SendAdminChatMessage = (prefix) => 
    {
        if(message.length > 0){
            ExecuteCommand(id, `${prefix}[12:37:15] ${userData.Login} (StaffPanel) ${message}`, "Staff chat message was sent successfully!");
            setMessage("");
        }
    }

    return(
        <div className="optionsPanelContainer">
            <div className="optionsPaneldescription" style={{textAlign:"center"}}>Write a message in the textbox and then select appropriate action.</div>

            <div className="staffChatLog">
                {   staffChat.map((messageData) => 
                        <StaffChatMessage messageData={messageData}/>
                    )
                }
                
            </div>

            <div className="txtAreaContainer" style={{width: "100%", height: "7.2rem"}}>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="txtArea" style={{width: "100%", height: "6rem"}} placeholder="Write a message to other staff members"></textarea>
            </div>

            <div className="staffChatButtonContainer">
                <button onClick={() => setMessage("")} style={{width:"50%", height: "6vh"}}>CLEAR INPUT</button>
                {/* <button onClick={() => SendAdminChatMessage("@@@")} style={{width:"20%", height: "6vh"}}>SEND SILENTLY</button> */}
                <button onClick={() => SendAdminChatMessage("@")} style={{width:"50%", height: "6vh"}}>SEND WITH BROADCAST</button>
                {/* <button onClick={() => SendAdminChatMessage("@@")} style={{width:"20%", height: "6vh"}}>SEND MONOSPACED</button> */}
                {/* <button style={{width:"20%", height: "6vh"}}>CLEAR CHAT</button> */}
            </div>
        </div>
    )
}

export default StaffChat;