const UserAddPanel = ({user, ChangeUsersSelection, selectedUsers}) => 
{
    return (
        <div className="listWidget" style={{padding:"10px"}}>
            <div className="UserAddPanel">
                <img className="steamProfileImage"  src={user.steamPlayer.avatarfull} alt="steam avatar icon" />
                <p>{user.panelUser.login}</p>

                { selectedUsers.find(x => x == user.panelUser.id) == null &&
                    <button onClick={() => ChangeUsersSelection(user.panelUser.id)}>Select User</button>
                }
                { selectedUsers.find(x => x == user.panelUser.id) != null &&
                    <button onClick={() => ChangeUsersSelection(user.panelUser.id)}>Unselect User</button>
                }
            </div>
        </div>
    );
}

export default UserAddPanel