const Items = [
    {id: -1, name: "None", categoryId : 0, show: false },
    {id: 0, name: "KeycardJanitor", categoryId : 0, show: true, url:"../Icons/Items/JanitorKeycard.webp" },
    {id: 1, name: "KeycardScientist", categoryId : 0, show: true, url:"../Icons/Items/ScientistKeycard.webp" },
    {id: 2, name: "KeycardResearchSupervisor", categoryId : 0, show: true, url:"../Icons/Items/ResearchSupervisorKeycard.webp" },
    {id: 3, name: "KeycardZoneManager", categoryId : 0, show: true, url:"../Icons/Items/ZoneManagerKeycard.webp" },
    {id: 4, name: "KeycardGuard", categoryId : 0, show: true, url:"../Icons/Items/GuardKeycard.webp" },
    {id: 5, name: "KeycardMTFPrivate", categoryId : 0, show: true, url:"../Icons/Items/MTFPrivateKeycard.webp" },
    {id: 6, name: "KeycardContainmentEngineer", categoryId : 0, show: true, url:"../Icons/Items/ContainmentEngineerKeycard.webp" },
    {id: 7, name: "KeycardMTFOperative", categoryId : 0, show: true, url:"../Icons/Items/MTFOperativeKeycard.webp" },
    {id: 8, name: "KeycardMTFCaptain", categoryId : 0, show: true, url:"../Icons/Items/MTFCaptainKeycard.webp" },
    {id: 9, name: "KeycardFacilityManager", categoryId : 0, show: true, url:"../Icons/Items/FacilityManagerKeycard.webp" },
    {id: 10, name: "KeycardChaosDevice", categoryId : 0, show: true, url:"../Icons/Items/ChaosInsurgencyDevice.webp" },
    {id: 11, name: "KeycardO5", categoryId : 0, show: true, url:"../Icons/Items/O5Keycard.webp" },
    {id: 12, name: "Radio", categoryId : 5, show: true, url:"../Icons/Items/Radio.webp" },
    {id: 13, name: "GunCOM15", categoryId : 1, show: true, url:"../Icons/Items/COM15.webp" },
    {id: 14, name: "Medkit", categoryId : 3, show: true, url:"../Icons/Items/Medkit.webp"  },
    {id: 15, name: "Flashlight", categoryId : 5, show: true, url:"../Icons/Items/Flashlight.webp" }, 
    {id: 16, name: "MicroHID", categoryId : 1, show: true, url:"../Icons/Items/MicroHID.webp" },
    {id: 17, name: "SCP500", categoryId : 4, show: true, url:"../Icons/Items/SCP500.webp" },
    {id: 18, name: "SCP207", categoryId : 4, show: true, url:"../Icons/Items/SCP207.webp" },
    {id: 19, name: "Ammo12gauge", categoryId : 2, show: true, url:"../Icons/Items/12gauge.webp" },
    {id: 20, name: "GunE11SR", categoryId : 1, show: true, url:"../Icons/Items/E11SR.webp" },
    {id: 21, name: "GunCrossvec", categoryId : 1, show: true, url:"../Icons/Items/Crossvec.webp" }, 
    {id: 22, name: "Ammo556x45", categoryId : 2, show: true, url:"../Icons/Items/556x45.webp" },
    {id: 23, name: "GunFSP9", categoryId : 1, show: true, url:"../Icons/Items/FSP9.webp" },
    {id: 24, name: "GunLogicer", categoryId : 1, show: true, url:"../Icons/Items/Logicer.webp" },
    {id: 25, name: "GrenadeHE", categoryId : 5, show: true, url:"../Icons/Items/Granade.webp" },
    {id: 26, name: "GrenadeFlash", categoryId : 5, show: true, url:"../Icons/Items/Flashbang.webp" },
    {id: 27, name: "Ammo44cal", categoryId : 2, show: true, url:"../Icons/Items/44cal.webp" },
    {id: 28, name: "Ammo762x39", categoryId : 2, show: true, url:"../Icons/Items/762x39.webp" }, 
    {id: 29, name: "Ammo9x19", categoryId : 2, show: true, url:"../Icons/Items/9x19.webp" },
    {id: 30, name: "GunCOM18", categoryId : 1, show: true, url:"../Icons/Items/COM18.webp" }, 
    {id: 31, name: "SCP018", categoryId : 4, show: true, url:"../Icons/Items/SCP018.webp" },
    {id: 32, name: "SCP268", categoryId : 4, show: true, url:"../Icons/Items/SCP268.webp" },
    {id: 33, name: "Adrenaline", categoryId : 3, show: true, url:"../Icons/Items/Adrenaline.webp" },
    {id: 34, name: "Painkillers", categoryId : 3, show: true, url:"../Icons/Items/Painkillers.webp" },
    {id: 35, name: "Coin", categoryId : 5, show: true, url:"../Icons/Items/Coin.webp" },
    {id: 36, name: "ArmorLight", categoryId : 5, show: true, url:"../Icons/Items/LightArmor.webp" },
    {id: 37, name: "ArmorCombat", categoryId : 5, show: true, url:"../Icons/Items/CombatArmor.webp" },
    {id: 38, name: "ArmorHeavy", categoryId : 5, show: true, url:"../Icons/Items/HeavyArmor.webp" },
    {id: 39, name: "GunRevolver", categoryId : 1, show: true, url:"../Icons/Items/Revolver.webp" },
    {id: 40, name: "GunAK", categoryId : 1, show: true, url:"../Icons/Items/AK.webp" },
    {id: 41, name: "GunShotgun", categoryId : 1, show: true, url:"../Icons/Items/Shotgun.webp" },
    {id: 42, name: "SCP330", categoryId : 4, show: true, url:"../Icons/Items/SCP330.webp" },
    {id: 43, name: "SCP2176", categoryId : 4, show: true, url:"../Icons/Items/SCP2176.webp" },
    {id: 44, name: "SCP244a", categoryId : 4, show: true, url:"../Icons/Items/SCP244a.webp" },
    {id: 45, name: "SCP244b", categoryId : 4, show: true, url:"../Icons/Items/SCP244b.webp" },
    {id: 46, name: "SCP1853", categoryId : 4, show: true, url:"../Icons/Items/SCP1853.webp" },
    {id: 47, name: "ParticleDisruptor", categoryId : 1, show: true, url:"../Icons/Items/ParticleDisruptor.webp" },
    {id: 48, name: "GunCom45", categoryId : 1, show: true, url:"../Icons/Items/COM45.webp" },
    {id: 49, name: "SCP1576", categoryId : 4, show: true, url:"../Icons/Items/SCP1576.webp" },
    {id: 50, name: "Jailbird", categoryId : 1, show: true, url:"../Icons/Items/Jailbird.webp" },
    {id: 51, name: "AntiSCP207", categoryId : 4, show: true, url:"../Icons/Items/AntiSCP207.webp" }, 
    {id: 52, name: "GunFRMG0", categoryId : 1, show: true, url:"../Icons/Items/FRMG0.webp" },
    {id: 53, name: "GunA7", categoryId : 1, show: true, url:"../Icons/Items/A7.webp" }
];
const ItemCategories = [
    { name: "Cards" },
    { name: "Weapons" },
    { name: "Ammunition" },
    { name: "Health" },
    { name: "SCP items" },
    { name: "Other" }
];

export {ItemCategories, Items}