import "./RoleManagement.css";
import playerRoles from "../../../../Data/PlayerRoles.js";
import { useState } from "react";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests.js";
import { useNavigate } from "react-router-dom";
import { FaLock, FaLockOpen } from "react-icons/fa";

const RoleManagement = ({GetPlayersGameIds, serverId}) =>
{
    const navigate = useNavigate;

    const [useSpawnPoint, setUseSpawnPoint] = useState(true);
    const [resetInventory, setResetInventory] = useState(true);
    const [role, setRole] = useState(playerRoles[0]);

    let roles = [[], [], [], []];

    for(let i = 1; i < playerRoles.length; i++)
    {
        if(!playerRoles[i].hide){
            roles[playerRoles[i].team - 1].push(playerRoles[i]);
        }
    }

    function onMouseEnter(e, color) {
        e.target.style.background = color.substring(0, color.length - 1) + ", 0.65)";
        e.target.style.color = "white";
    }

    function onMouseLeave(e, color) {
        e.target.style.background = "none";
        e.target.style.color = color;
    }

    function ChangePlayerClass() 
    {
        if(role != playerRoles[0] && GetPlayersGameIds() != "")
        {
            let options = useSpawnPoint ? 2 : 0 + resetInventory ? 1 : 0;
            ExecuteCommand(serverId, "/forceclass " + GetPlayersGameIds() + " " + role.id + " " + options, navigate);
        }
    }

    return(
        <div className="optionsPanelContainer">
            <div className="optionsPaneldescription">
                Select player(s) and then assign the role.
            </div>

            <div className="roleCategoriesContainer">
                
                <fieldset className="roleCategoryFieldset">
                    <legend>SCP</legend>
                    {roles[0].map((role) => 
                        <button onMouseEnter={(e) => onMouseEnter(e, role.color)} onMouseLeave={(e) => onMouseLeave(e, role.color)} onClick={() => {setRole(role)}} style={{color: role.color, borderColor: role.color}} key={role.name}>{role.name}</button>
                    )}
                </fieldset>

                <fieldset className="roleCategoryFieldset">
                    <legend>NTF Team</legend>
                    {roles[1].map((role) => 
                        <button onMouseEnter={(e) => onMouseEnter(e, role.color)} onMouseLeave={(e) => onMouseLeave(e, role.color)} onClick={() => {setRole(role)}} style={{color: role.color, borderColor: role.color}} key={role.name}>{role.name}</button>
                    )}
                </fieldset>

                <fieldset className="roleCategoryFieldset">
                    <legend>Chaos Team</legend>
                    {roles[2].map((role) => 
                        <button onMouseEnter={(e) => onMouseEnter(e, role.color)} onMouseLeave={(e) => onMouseLeave(e, role.color)} onClick={() => {setRole(role)}} style={{color: role.color, borderColor: role.color}} key={role.name}>{role.name}</button>
                    )}
                </fieldset>

                <fieldset className="roleCategoryFieldset">
                    <legend>Other roles</legend>
                    {roles[3].map((role) => 
                        <button onMouseEnter={(e) => onMouseEnter(e, role.color)} onMouseLeave={(e) => onMouseLeave(e, role.color)} onClick={() => {setRole(role)}} style={{color: role.color, borderColor: role.color}} key={role.name}>{role.name}</button>
                    )}
                </fieldset>

            </div>

            <div className="roleActionContainer">
                <div className="roleConfirmationContainer">
                    <div className="targetRoleInfo">
                        <label>Target role: </label>
                        <label style={{fontSize: "2rem", color: role.color}}>{role.name}</label>
                    </div>
                    
                    <button onClick={() => { ChangePlayerClass() }}>SET CLASS</button>
                    
                </div>
                <div className="roleOptionsContainer">
                    <button>GRANT LOADOUT</button>

                    { useSpawnPoint &&
                        <button className="selectedButton" onClick={() => setUseSpawnPoint(false)}><FaLockOpen style={{marginRight: "0.5vw"}} />Use Spawnpoint</button>
                    }
                    { !useSpawnPoint &&
                        <button className="unselectedButton" onClick={() => setUseSpawnPoint(true)}><FaLock style={{marginRight: "0.5vw"}}/>Use Spawnpoint</button>
                    }
                    
                    { resetInventory &&
                        <button className="selectedButton" onClick={() => setResetInventory(false)}><FaLockOpen style={{marginRight: "0.5vw"}} />Reset Inventory</button>
                    }
                    { !resetInventory &&
                        <button className="unselectedButton" onClick={() => setResetInventory(true)}><FaLock style={{marginRight: "0.5vw"}} />Reset Inventory</button>
                    }
                    
                </div>  
            </div>
        </div>
    );
}

export default RoleManagement;