let ArraySettingFunc = null;
let SetRefresh = null;
let data = []

const SetOnStart = (func, setRefresh) => {
    ArraySettingFunc = func;
    SetRefresh = setRefresh;
}

const RemoveInfoBoxMessage = (index) => {
    data.splice(index, 1);
    ArraySettingFunc(data);
    SetRefresh(data + index + "_" + data.length)
}

const AddInfoBoxMessage = (text, type) => {
    data.push({text: text, type: type});
    ArraySettingFunc(data);
    SetRefresh(data + "_" + text + "_" + data.length)
}

export {SetOnStart, AddInfoBoxMessage, RemoveInfoBoxMessage};