import { useState } from "react";
import AccountSettings from "../Account/Account";
import GroupButtons from "../Elements/GroupButtons/GroupButtons";
import PanelLogsList from "./PanelLogs/PanelLogsList.js";
import UserSettings from "./UserSettings/UserSettings";
import ServerGroupsList from "./ServerGroupsList/ServerGroupsList.js";

const Settings = () => {

    const [selectedCategory, setSelectedCategory] = useState(0);

    return(
        <>
            <div style={{margin:"20px 10%"}}>
                <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "Users"}, {text: "Panel Logs"}, {text: "Server Groups"}]}/>
            </div>
            

            { selectedCategory == 0 && <UserSettings/> }
            { selectedCategory == 1 && <PanelLogsList/> }
            { selectedCategory == 2 && <ServerGroupsList/> }
        </>
    )
}

export default Settings;