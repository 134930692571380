const PredefinedReasonData =
[
    { Reason: "Toksyczność", Time: "1d" },
    { Reason: "Mocna toksyczność", Time: "3d" },
    { Reason: "Team Killing", Time: "3d" },
    { Reason: "Team Killing", Time: "14d" },
    { Reason: "Mass Team Killing", Time: "31d" },
    { Reason: "Mass Team Killing", Time: "50y" },
    { Reason: "Nadużywanie mikrofonu", Time: "1d" },
    { Reason: "Bindy niezgodne z regulaminem", Time: "1d" },
    { Reason: "Ujawnianie administracji na hidetag", Time: "1d" },
    { Reason: "Utrudnianie pracy administratora", Time: "3d" },
    { Reason: "Nieregulaminowy teaming", Time: "1d" },
    { Reason: "Troll", Time: "50y" },
    { Reason: "Raid", Time: "50y" },
    { Reason: "Używanie błędów gry", Time: "50y" },
    { Reason: "Cheaty", Time: "50y" },
    { Reason: "Reklamowanie bez zgody", Time: "50y" },
];

export default PredefinedReasonData;