import axios from "axios";
import { Cookies } from "react-cookie";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/ServerGroupUsers/';
const cookies = new Cookies();

const GetAllGroupUsers = (groupId, SetUsers) => 
{
    axios.get(api_url + "Get/" + groupId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetUsers(res.data);
    }).catch((err) => 
    {
        console.error(err);
    });
}

const AddUserToGroup = (groupId, userId, Permissions, onSuccess) => 
{
    const data = {
        ServerGroupId: groupId,
        UserId : userId,
        Permissions: Permissions
    };

    axios.post(api_url + "Add", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        onSuccess();
    }).catch((err) => 
    {
        console.error(err);
    });
}

const EditUserInGroup = (UserToServerGroupId, Permissions, groupId, SetUsers) => 
{
    const data = {
        Permissions: Permissions,
        UserToServerGroupId : UserToServerGroupId,
    };

    axios.post(api_url + "Edit", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetAllGroupUsers(groupId, SetUsers);
    }).catch((err) => 
    {
        console.error(err);
    });
}

const RemoveUserFromGroup = (userToGroupId, groupId, SetUsers) => 
{
    axios.delete(api_url + "Remove/"+userToGroupId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetAllGroupUsers(groupId, SetUsers);
    }).catch((err) => 
    {
        console.error(err);
    });
}

export { GetAllGroupUsers, AddUserToGroup, EditUserInGroup, RemoveUserFromGroup };