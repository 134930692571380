import { FiUser, FiShield } from "react-icons/fi";
import { FaSteam } from "react-icons/fa";
import "./PlayerButton.css"
import playerRoles from "../../../../Data/PlayerRoles.js"
import { useState } from "react";

function PlayerButton({playerData, ChangePlayerSelection, isSelected}) 
{
    let steamURL = playerData.userId.includes("@") ? "https://steamcommunity.com/profiles/" + playerData.userId.split('@')[0] : "";
    
    const OpenSteam = () => {
      window.open(steamURL, "_blank");
    }
    return(
        <div key={"player_" + playerData.id} className={"playerButton " + (isSelected ? " playerButtonSelected" : " ")} onClick={() => ChangePlayerSelection(playerData) }>
        <div className="playerInfo">
          <div className="playerRankNick">
            <div className="playerRank">
                {playerData.isAdmin == false && <FiUser/>}
                {playerData.isAdmin == true && <FiShield/>}
            </div>
            <div className="playerNickAndId" style={{display: "flex"}}>
              <p className="playerId">[{playerData.inGameId}]</p>
              <p className="playerNick">{playerData.nickname}</p>
            </div>
          </div>
          <div className="playerClass" style={{color: playerRoles[playerData.playerRoleId + 1].color}}>{playerRoles[playerData.playerRoleId + 1].name}</div>
        </div>
        {playerData.userId.split('@')[1] == "steam" && 
          <div className="playerSteamButton iconButton" onClick={() => OpenSteam()}><FaSteam size="3vh"/></div>
        }
      </div>
    );
}
export default PlayerButton;