import { useEffect, useState } from "react";
import { GetLogs } from "../Scripts/Requests/ServerRequests";
import { useNavigate, useParams } from "react-router-dom";
import ServerLogPanel from "./ServerLogPanel/ServerLogPanel";

const ServerLogs = () => {

    const [serverLogs, SetServerLogs] = useState([]);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        GetLogs(id, SetServerLogs, navigate)
    }, [])

    return (
        <div className="widgetListContainer">
            {serverLogs.map((log) => <ServerLogPanel log={log}/>)}
        </div>
    )
}

export default ServerLogs;