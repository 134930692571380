import axios from "axios";
import { Cookies } from "react-cookie";
import { AddInfoBoxMessage } from "../InfoBoxManager";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/ServerUsersPanel/';
const cookies = new Cookies();

const GetAllUsers = (serverId, SetPlayers) => 
{
  axios.get(api_url + "GetAll/" + serverId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
  .then((res) => 
  {
    SetPlayers(res.data);
  }).catch((err) => 
    {
      
    }
  );
}

const GetPlayerIP = (serverId, playerId, SetPlayerIP) => {
  axios.get(api_url + "GetPlayerIP/"+serverId + "/" + playerId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
  .then((res) => 
  {
    SetPlayerIP(res.data);
  }).catch((err) => 
    {
      
    }
  );
}

const BanPlayersWhoLeft = (serverId, players, reason, Time) => 
{
  if(players.length < 1)
    return;
  
  const data = {
    PlayersToBanList: players,
    ServerId: serverId,
    Reason: reason,
    Time: Time
  }
  axios.post(api_url + "BanOfflinePlayers", data, {headers: {Authorization: "Bearer " + cookies.get("jwt"), "Content-Type": "application/json" }})
  .then((res) => 
  { 
    AddInfoBoxMessage("Player(s) who left the server have been successfully banned!", 1);
  }).catch((error) => 
  {
      AddInfoBoxMessage(error.message, 0);
  });
}

export { GetAllUsers, GetPlayerIP, BanPlayersWhoLeft };