import "./Login.css";
import { LoginSecondStep, LoginFirstStep } from "../../Scripts/Requests/AuthRequests.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => 
{
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [loginOrEmail, setLoginOrEmail] = useState("");
    const [code, setCode] = useState("");

    const [codeId, setCodeId] = useState(0);
    const [error, setError] = useState("");

    const [loginStep, setLoginStep] = useState(0);

    return (
        <div className="panelField">
            <div className="loginPanelContainer">
                <div className="loginForm">
                <label style={{fontSize: "2rem", marginBottom:"3vh"}}>Log in to the panel</label>

                { loginStep == 0 &&
                    <>
                        <label>Login</label>
                        <input onChange={(e) => setLoginOrEmail(e.target.value)} type="text" className="accountDataBox"></input>

                        <label>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} type="password" className="accountDataBox"></input>

                        <button onClick={() => LoginFirstStep(loginOrEmail, password, setCodeId, setLoginStep, setError)} style={{width: "50%"}}>Log in</button>                    
                    </>
                }
       
                { loginStep == 1 &&
                    <>
                        <label>Your Code</label>
                        <input onChange={(e) => setCode(e.target.value)} type="text" className="accountDataBox"></input>

                        <div style={{display: "flex", width:"100%", marginTop: "25px"}}>
                            <button onClick={() => setLoginStep(0)} style={{width: "50%"}}>Back</button>
                            <button onClick={() => LoginSecondStep(code, codeId, navigate, setError)} style={{width: "50%"}}>Log in</button>   
                        </div>
                    </>
                }

                {error.length > 1 &&
                    <div className="errorBox">{error}</div>
                }

                <p style={{color:"red", marginBottom:"0px"}}>Przed zalogowaniem się sprawdź, czy nie udostępniasz ekranu!</p>
                <p style={{marginTop:"5px"}}>Konta są tworzone przez administratora panelu.</p>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;