import { useState } from "react";
import GroupButtons from "../../../Elements/GroupButtons/GroupButtons";

const MapControl = () => {
    const [selectedCategory, setSelectedCategory] = useState(0);
    
    return (
        <div className="optionsPanelContainer">
            <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "DOOR MANAGEMENT"}, {text: "ELEVATOR MANAGEMENT"}, {text: "MAP MODIFIERS"}]}/>
            { selectedCategory == 0 &&
            <div>
                DOOR MANAGEMENT
            </div>
            }
            { selectedCategory == 1 &&
                <div>
                    ELEVATOR MANAGEMENT
                </div>
            }
            { selectedCategory == 2 &&
                <div>
                    MAP MODIFIERS
                </div>
            }                 
        </div>
    );
}

export default MapControl;