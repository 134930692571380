import { useNavigate } from "react-router-dom";
import { Logout } from "../../../Scripts/Requests/AuthRequests";
import "./SessionPanel.css";
import { FaMobileAlt, FaLaptop, FaPowerOff } from "react-icons/fa";

const SessionPanel = ({currentTokenId, sessionData, refresh}) => {
    const navigate = useNavigate();

    const isCurrent = currentTokenId == sessionData.id;
    const className = isCurrent ? "loginSessionBlock loginSessionBlockCurrent" : "loginSessionBlock";

    return (
        <div>
            <div className={className}>
                <div className="tableContainer">
                    <table>
                        <tr>
                            <th className="cellInfo">Login date:</th>
                            <th className="cellData">{sessionData.loginDate}</th>
                        </tr>

                        <tr>
                            <th className="cellInfo">IP:</th>
                            <th className="cellData">{sessionData.ip}</th>
                        </tr>

                        <tr>
                            <th className="cellInfo">Info:</th>
                            <th className="cellData">
                                { sessionData.isMobile &&
                                    <FaMobileAlt size="1.25em"/>
                                }

                                { !sessionData.isMobile &&
                                    <FaLaptop size="1.25em"/>
                                }
                                <p style={{marginLeft:"7px"}}>{sessionData.userAgent}</p>
                            </th>
                        </tr>

                    </table>
                </div>
                
                <div className="logoutButtonContainer">
                    { isCurrent &&
                        <p>Current Sessions</p>
                    }
                    { !isCurrent &&
                        <>
                            { sessionData.active &&
                                <button onClick={() => Logout(sessionData.id, navigate, false, refresh)}><FaPowerOff style={{marginRight: "0.4vw"}}/>Logout</button>
                            }
                            { !sessionData.active &&
                                <p style={{color:"red"}}>Inactive</p>
                            }                        
                        </>
                    }                  
                </div>
                
            </div>
        </div>
    );
}

export default SessionPanel;