import axios from "axios";
import { Cookies } from "react-cookie";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/ServerGroupServers/';
const cookies = new Cookies();

const GetAllGroupServers = (groupId, SetServers) => 
{
    axios.get(api_url + "Get/" + groupId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServers(res.data);
    }).catch((err) => 
    {
        console.error(err);
    });
}

const AddServerToGroup = (groupId, serverId, onSuccess) => 
{
    const data = {
        GroupId: groupId,
        ServerId : serverId,
    };

    axios.post(api_url + "Add", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        onSuccess();
    }).catch((err) => 
    {
        console.error(err);
    });
}

const RemoveServerFromGroup = (serverToGroupId, groupId, SetServers) => 
{
    axios.delete(api_url + "Remove/"+serverToGroupId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetAllGroupServers(groupId, SetServers);
    }).catch((err) => 
    {
        console.error(err);
    });
}

export {GetAllGroupServers, AddServerToGroup, RemoveServerFromGroup};