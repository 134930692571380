import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const PlayerJoinLeftLog = ({content, joined}) => {
    const splitted = content.split(";");
    return (
        <>
            { joined &&
                <div style={{display: "flex"}}>
                    <FaArrowCircleRight size={"30px"} color="lime"/>
                    <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Player Joined</h2>
                </div>
            }
            { !joined &&
                <div style={{display: "flex"}}> 
                    <FaArrowCircleLeft size={"30px"} color="red"/>
                    <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Player Left</h2>
                </div>
            }
            
            <div style={{marginLeft:"20px" }}>
                <div>Nick: {splitted[2]}</div>
                <div>Player Id: {splitted[0]}</div>
                <div>User Id: {splitted[1]}</div>
                <div>IP: {splitted[3]}</div>
            </div>

        </>
    )
}

export default PlayerJoinLeftLog;