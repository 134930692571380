import { AddServerToGroup } from "../../../Scripts/Requests/ServerGroupServersRequest";

const ServerAddPanel = ({groupId, server, onSuccess}) =>{

    const splittedName = server.name.split("<color=#00000000>")[0];
    const serverName = splittedName
    .replaceAll("<color=", "<span style='color:")  
    .replaceAll(">", "''>")
    .replaceAll("</color''}>", "</span>")
    .replaceAll("b''>", "b>");

    return (
        <div className="listWidget" style={{padding:"10px"}}>
            <div style={{display: "flex"}}>
                <div style={{fontSize:"20px", marginTop:"12px", width:"87%"}} dangerouslySetInnerHTML={{__html: serverName}}></div>
                <button onClick={() => AddServerToGroup(groupId, server.id, onSuccess)}>Add Server</button>
            </div>
        </div>
    )
}

export default ServerAddPanel;