const broadcastTime = [
    1,
    5,
    10,
    15,
    20,
    25,
    30,
    45,
    60,
    120,
    240,
    300
];

export default broadcastTime;