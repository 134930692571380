const Permissions = {
    Root : 1,
    Admin : 2,
}

const PermissionsNames = [
    { Enum: "Root",  Name: "Root" },
    { Enum: "Admin",  Name: "Admin" },
]

export { Permissions, PermissionsNames };