import { useParams } from "react-router-dom";
import GroupButtons from "../Elements/GroupButtons/GroupButtons";
import { useEffect, useState } from "react";
import "./ServerManagement.css";
import { GetPunishmentsData } from "../../Scripts/Requests/ServerRequests";

const ServerManagement = () => {
    const { id } = useParams();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [data, SetData] = useState([]);


    useEffect(() => {
        GetPunishmentsData(id, SetData);
    }, [])

    if(data.length < 3)
        return (<></>);

    return (
        <div className="serverManagement">
            <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "Mutes"}, {text: "Bans"}, {text: "IP Bans"}]} />
            { selectedCategory == 0 &&
                <div className="widgetListContainer" style={{height:"72vh"}}>
                    {data[2].map(punishment => <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>{punishment}</div>)}
                </div>
            }
            { selectedCategory == 1 &&
                <div className="widgetListContainer" style={{height:"72vh"}}>
                    {data[0].map(punishment => <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>{punishment}</div>)}
                </div>
            } 
            { selectedCategory == 2 &&
                <div className="widgetListContainer" style={{height:"72vh"}}>
                    {data[1].map(punishment => <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>{punishment}</div>)}
                </div>
            }                          
        </div>
    )
}

export default ServerManagement;