import { useEffect, useState } from "react";
import ModalWindow from "../../../Elements/ModalWindow/ModalWindow";
import { ChangePassword, GetSessions } from "../../../../Scripts/Requests/AuthRequests";
import { FaKey, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SessionPanel from "../../Elements/SessionPanel";
import "./AccountSecurity.css";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { FaCircleCheck } from "react-icons/fa6";
import { AddInfoBoxMessage } from "../../../../Scripts/InfoBoxManager";

const AccountSecurity = () => {
    const cookies = new Cookies();
    const userData = jwtDecode(cookies.get("jwt"));

    const [sessions, setSessions] = useState([]);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

    const [changePasswordWindow, setChangePasswordWindow] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        GetSessions(setSessions);
    }, []);

    const containsSpecialCharacters = newPassword.match(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/);
    const containsDigit = newPassword.match(/\d+/g);
    const lengthCorrect = (newPassword.length >= 9 && newPassword.length < 32);
    const containsUppercase = newPassword.match(/[A-Z]/);
    const containsLowercase = newPassword.match(/[a-z]/);

    const ChangePasswordFunc = () => {

        if(containsSpecialCharacters && containsDigit && lengthCorrect && containsUppercase && containsLowercase)
        {
            if(newPasswordRepeat != newPassword)
            {
                AddInfoBoxMessage("The new passwords do not match.", 0);
                return;
            }

            if(newPasswordRepeat == oldPassword)
            {
                AddInfoBoxMessage("The new password must be different from the old password.", 0);
                return;
            }

            ChangePassword({OldPassword: oldPassword, NewPassword: newPassword}, navigate, setChangePasswordWindow);
        } else
        {
            AddInfoBoxMessage("The password does not meet the required security standards.", 0);
        }
    }

    return(
        <>
            <div className="accountInfoTitle">Password</div>
            <div className="profileDataTableContainer tableStyles">
                <table>
                    <tr>
                        <th className="infoCell">Password Last Changed:</th>
                        <th className="dataCell">{userData.PasswordChanged}</th>
                    </tr>
                </table>
            </div>
            <button style={{width:"calc(50%)"}} onClick={() => setChangePasswordWindow(true)}><FaKey style={{marginRight: "0.4vw"}}/>Change Password</button>

            <div style={{marginTop:"75px"}} className="accountInfoTitle">Sessions:</div>
            <div className="blocksContainer">
                { sessions.map(session => 
                    <SessionPanel currentTokenId={userData.RefreshTokenId} sessionData={session} refresh={() => GetSessions(setSessions)}/>
                )}
            </div>

            { changePasswordWindow &&
                    <ModalWindow showCloseButton={true} closeFunction={() => setChangePasswordWindow(false)} width="750px" title={"Change Password"}
                        content={
                            <>
                                <label>Old password</label>
                                <input onChange={(e) => setOldPassword(e.target.value)} className="inputStyle" type="password" style={{width: "98%"}}/>

                                <label>New password</label>
                                <input onChange={(e) => setNewPassword(e.target.value)} className="inputStyle" type="password" style={{width: "98%"}}/>

                                <label>Repeat new password</label>
                                <input onChange={(e) => setNewPasswordRepeat(e.target.value)} className="inputStyle" type="password" style={{width: "98%"}}/>

                                <div className="passwordChecksContainer">
                                    {/* Password Length */}
                                    { lengthCorrect &&
                                        <div>
                                            <FaCircleCheck color="green" size={"25px"}/> 
                                            <p>Password length is correct.</p>
                                        </div>
                                    }
                                    { !lengthCorrect &&
                                        <div>
                                            <FaTimesCircle color="red" size={"25px"}/>
                                            <p>Password length must be between 9 and 32 characters!</p>
                                        </div>
                                    }

                                    {/* Contains digit */}
                                    { containsDigit &&
                                        <div>
                                            <FaCircleCheck color="green" size={"25px"}/> 
                                            <p>Password contains at least one digit.</p>
                                        </div>
                                    }
                                    { !containsDigit &&
                                        <div>
                                            <FaTimesCircle color="red" size={"25px"}/>
                                            <p>Password should contain at least one digit!</p>
                                        </div>
                                    }

                                    {/* Special character */}
                                    { containsSpecialCharacters &&
                                        <div>
                                            <FaCircleCheck color="green" size={"25px"}/> 
                                            <p>Password contains at least one special character.</p>
                                        </div>
                                    }
                                    { !containsSpecialCharacters &&
                                        <div>
                                            <FaTimesCircle color="red" size={"25px"}/>
                                            <p>Password should contain at least one special character!</p>
                                        </div>
                                    }

                                    {/* Uppercase character */}
                                    { containsUppercase &&
                                        <div>
                                            <FaCircleCheck color="green" size={"25px"}/> 
                                            <p>Password contains at least one uppercase letter.</p>
                                        </div>
                                    }
                                    { !containsUppercase &&
                                        <div>
                                            <FaTimesCircle color="red" size={"25px"}/>
                                            <p>Password should contain at least one uppercase letter!</p>
                                        </div>
                                    }

                                    {/* Lowercase character */}
                                    { containsLowercase &&
                                        <div>
                                            <FaCircleCheck color="green" size={"25px"}/> 
                                            <p>Password contains at least one lowercase letter.</p>
                                        </div>
                                    }
                                    { !containsLowercase &&
                                        <div>
                                            <FaTimesCircle color="red" size={"25px"}/>
                                            <p>Password should contain at least one lowercase letter!</p>
                                        </div>
                                    }
                                </div>


                                <button onClick={() => ChangePasswordFunc()} style={{width: "98%", height:"5vh", marginTop:"2vh"}}><FaKey style={{marginRight:"0.5vw"}}/>Change Password</button>
                            </>
                        }
                    />
                }
        </>
    );
}
export default AccountSecurity;