import { FaClock, FaCommentDots } from "react-icons/fa";
import { ImHammer2 } from "react-icons/im";
import "./PredefinedReason.css"

const PredefinedReason = ({reason, time, ExecuteBanUser}) => {
    return (
        <div onClick={() => ExecuteBanUser(time, reason)} className="predefinedReason">
            <button className="banBtn"><ImHammer2 style={{marginRight:"0.5vw"}}/>BAN [<FaClock style={{marginRight:"0.25vw"}}/>{time}]</button>
            <div className="reasonDesc"><FaCommentDots style={{marginRight:"0.5vw"}}/>{reason}</div>
        </div>
    );
}

export default PredefinedReason;