import axios from "axios";
import { Cookies } from "react-cookie";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/PanelLogs/';
const cookies = new Cookies();

const GetPanelLogs = (setLogs) => {

    axios.get(api_url + 'Get', {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        setLogs(res.data);
    })
    .catch(() => 
    {
        //navigate("/");
    });
}

export { GetPanelLogs }