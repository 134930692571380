import { IoLogoGameControllerB } from "react-icons/io";
import { FaServer, FaUser } from "react-icons/fa";
import { CheckPermission } from "../../../../Scripts/PermissionsManager";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { Permissions } from "../../../../Data/Permissions";
import { SCPPermissions } from "../../../../Data/SCP/SCPPermissions";

const RaNavigation = ({SetSelectedPanel, PermissionsInGroup}) =>
{
  const cookies = new Cookies();

  let userPermissions = 0;
  if(cookies.get("jwt")){
    userPermissions = jwtDecode(cookies.get("jwt")).Permissions;
  }

  const GetButtonClass = (requiredPermission) => 
  {
    return CheckPermission(PermissionsInGroup, userPermissions, requiredPermission, true) ? " categoryBtnActive" : "";
  }
  
  const GetModerationButtonClass = () => {
    const permissions = userPermissions;

    const isPermitted = CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Ban, true) || 
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Kick, true) ||
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Mutes, true);

    return isPermitted ? " categoryBtnActive" : "";
  }

  const GetRoundEventsButtonClass = () => {
    const permissions = userPermissions;

    const isPermitted = CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_RoundEvents, true) || 
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_RespawnEvents, true);

    return isPermitted ? " categoryBtnActive" : "";
  }

  const SelectCategory = (num, permission) => {
    const permissions = userPermissions;

    if(CheckPermission(PermissionsInGroup, permissions, permission, true))
      SetSelectedPanel(num);
  }

  const SelectModeration = () => {
    const permissions = userPermissions;

    const isPermitted = CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Ban, true) || 
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Kick, true) ||
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_Mutes, true);
    
    if(isPermitted)
      SetSelectedPanel(4);
  }

  const SelectRoundEvents = () => {
    const permissions = userPermissions;

    const isPermitted = CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_RoundEvents, true) || 
                        CheckPermission(PermissionsInGroup, permissions, SCPPermissions.SCP_RespawnEvents, true);
    
    if(isPermitted)
      SetSelectedPanel(8);
  }

  return(
      <div className="categoriesSidebar">
        <div className="playerManagementContainer managementContainer">
          <div className="categoryTitle">
            <div className="titleLine"></div>
            <FaUser size="3vh"/>&nbsp;PLAYER
            <div className="titleLine"></div>
          </div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_UserData)} onClick={() => SelectCategory(0, SCPPermissions.SCP_UserData)}>Request data</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_RoleManagement)} onClick={() => SelectCategory(1, SCPPermissions.SCP_RoleManagement)}>Role management</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_Inventory)} onClick={() => SelectCategory(2, SCPPermissions.SCP_Inventory)}>Inventory</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_StatusEffects)} onClick={() => SelectCategory(3, SCPPermissions.SCP_StatusEffects)}>Status effects</div>
        </div>

        <div className="serverManagementContainer managementContainer">
          <div className="categoryTitle">
            <div className="titleLine"></div>
            <FaServer size="3vh"/>&nbsp;SERVER
            <div className="titleLine"></div>
          </div>
          <div className={"categoryBtn" + GetModerationButtonClass()} onClick={() => SelectModeration()}>Moderation</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_Administration)} onClick={() => SelectCategory(5, SCPPermissions.SCP_Administration)}>Administration</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_Broadcasting)} onClick={() => SelectCategory(6, SCPPermissions.SCP_Broadcasting)}>Broadcasting</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_StaffChat)} onClick={() => SelectCategory(7, SCPPermissions.SCP_StaffChat)}>Staff Chat</div>
        </div>

        <div className="gameManagementContainer managementContainer">
          <div className="categoryTitle">
            <div className="titleLine"></div>
            <IoLogoGameControllerB size="3vh"/>&nbsp;GAME
            <div className="titleLine"></div>
          </div>
          <div className={"categoryBtn" + GetRoundEventsButtonClass()} onClick={() => SelectRoundEvents()}>Round & Events</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_MapControl)} onClick={() => SelectCategory(9, SCPPermissions.SCP_MapControl)}>Map control</div>
          <div className={"categoryBtn" + GetButtonClass(SCPPermissions.SCP_Cassie)} onClick={() => SelectCategory(10, SCPPermissions.SCP_Cassie)}>C.A.S.S.I.E</div>
        </div>
      </div>
  );
}

export default RaNavigation;