import axios from "axios";
import { Cookies } from "react-cookie";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/PanelUsers/';
const cookies = new Cookies();

const GetUser = (userId, SetUser) => 
    {
        axios.get(api_url + "Get/" + userId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
        .then((res) => 
        {
            SetUser(res.data);
        })
        .catch((err) => 
        {
            console.error(err);
        });
    }

const GetUsers = (SetUsers, navigate) => 
{
    axios.get(api_url + "GetAll", {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetUsers(res.data);
    })
    .catch(() => 
    {
        navigate("/");
    });
}
const AddUser = (UserData, SetUsers, navigate) => {
    axios.post(api_url + "AddNewUser", UserData, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetUsers(SetUsers, navigate);
    })
    .catch(() => 
    {
        navigate("/")
    });
}
const EditUser = (UserData, SetUsers, navigate) => {
    axios.post(api_url + "EditUser", UserData, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetUsers(SetUsers, navigate);
    })
    // .catch(() => 
    // {
    //     navigate("/")
    // });
}
const RemoveUser = (Id, SetUsers, navigate) => {
    axios.delete(api_url + "RemoveUser/" + Id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then(() => 
    {
        GetUsers(SetUsers, navigate);
    })
    .catch(() => 
    {
        navigate("/")
    });
}

const GetSteamData = (Id, SetData, navigate) => {
    axios.get(api_url + "GetUserSteamData/"+Id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetData(res.data.response.players)
    })
    .catch(() => 
    {
        navigate("/")
    });
}

const ResetPassword = (Id, navigate) => {
    axios.post(api_url + "ResetPassword/" + Id, null, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        console.log(res);
    })
    .catch(() => 
    {
        navigate("/")
    });
}

export { GetUsers, AddUser, EditUser, RemoveUser, ResetPassword, GetSteamData, GetUser }