// None - 0
// SCPs - 1
// FoundationForces, Scientists - 2
// ChaosInsurgency, ClassD - 3
// Dead, OtherAlive - 4 

const playerRoles = [        
    { id: "None", name: "None", color: "rgb(255, 255, 255)", team: 0 },
    { id: "SCP173", name: "SCP-173", color: "rgb(236, 34, 34)", team: 1 },
    { id: "ClassD", name: "Class-D", color: "rgb(255, 142, 0)", team: 3 },
    { id: "Spectator", name: "Spectator", color: "rgb(255, 255, 255)", team: 4 },
    { id: "SCP106", name: "SCP-106", color: "rgb(236, 34, 34)", team: 1 },
    { id: "NtfSpecialist", name: "Ntf Specialist", color: "rgb(0, 150, 255)", team: 2 },
    { id: "SCP049", name: "SCP-049", color: "rgb(236, 34, 34)", team: 1 },
    { id: "Scientist", name: "Scientist", color: "rgb(255, 255, 124)", team: 2 },
    { id: "SCP079", name: "SCP-079", color: "rgb(236, 34, 34)", team: 1 },
    { id: "ChaosConscript", name: "Chaos Conscript", color: "rgb(0, 143, 29)", team: 3 },
    { id: "SCP096", name: "SCP-096", color: "rgb(236, 34, 34)", team: 1 },
    { id: "SCP0492", name: "SCP-049-2", color: "rgb(236, 34, 34)", team: 1 },
    { id: "NtfSergeant", name: "Ntf Sergeant", color: "rgb(0, 61, 202)", team: 2 },
    { id: "NtfCaptain", name: "Ntf Captain", color: "rgb(0, 150, 255)", team: 2 },
    { id: "NtfPrivate", name: "Ntf Private", color: "rgb(112, 195, 255)", team: 2 },
    { id: "Tutorial", name: "Tutorial", color: "rgb(247, 0, 253)", team: 4 },
    { id: "FacilityGuard", name: "Facility Guard", color: "rgb(91, 99, 112)", team: 2 },
    { id: "SCP939", name: "SCP-939", color: "rgb(236, 34, 34)", team: 1 },
    { id: "CustomRole", name: "Custom Role", color: "rgb(255, 255, 255)", team: 4, hide: true },
    { id: "ChaosRifleman", name: "Chaos Rifleman", color: "rgb(0, 143, 29)", team: 3 },
    { id: "ChaosMarauder", name: "Chaos Marauder", color: "rgb(0, 104, 38)", team: 3 },
    { id: "ChaosRepressor", name: "Chaos Repressor", color: "rgb(13, 125, 53)", team: 3 },
    { id: "Overwatch", name: "Overwatch", color: "rgb(0, 247, 255)", team: 4 },
    { id: "Filmmaker", name: "Filmmaker", color: "rgb(61, 61, 61)", team: 4 }];

export default playerRoles;