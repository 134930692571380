import { Permissions } from "../Data/Permissions";

const CheckPermission = (groupPermissions, userPermissions, permissionRequired, canAdmin) => 
{
    return ((groupPermissions & permissionRequired) != 0 || (userPermissions & Permissions.Admin && canAdmin) || (userPermissions & Permissions.Root)) == true;
}

const GetPermissionsToDisplay = ({permissions, permissionsNames, permissionsEnum}) => {
    return (
        <>
            { permissions == 0 && <div>None</div> }
            {
                permissionsNames.map(permissionName => 
                    {
                        if(permissions & permissionsEnum[permissionName.Enum]) { return <div>{ permissionName.Name }</div> }
                    }
                )
            }
        </>
    )
}

export { CheckPermission, GetPermissionsToDisplay };