import { useEffect, useState } from "react";
import { GetPanelLogs } from "../../../Scripts/Requests/PanelLogsRequests.js";
import LogElement from "./LogElement/LogElement.js";

const PanelLogsList = () => {

    const [panelLogsData, setPanelLogsData] = useState([]);

    useEffect(() => {
        GetPanelLogs(setPanelLogsData);
    }, [])
    return(
        <>
            <div className="widgetListContainer" style={{maxHeight: "80%"}}>
                { panelLogsData.map((log) => <LogElement logData={log} />) } 
            </div>
        </>
    );
}

export default PanelLogsList;