import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";
import { GetAllGroupServers } from "../../../Scripts/Requests/ServerGroupServersRequest";
import { GetServers } from "../../../Scripts/Requests/ServerRequests";
import { useNavigate } from "react-router-dom";
import ServerGroupServerPanel from "../Elements/ServerGroupServerPanel";
import ServerAddPanel from "../Elements/ServerAddPanel";

const ServerGroupServerList = ({groupId, ServerGroup}) => {
    const navigate = useNavigate();

    const [addServerWindow, setAddServerWindow] = useState(false);
    const [serversInGroup, setServersInGroup] = useState([]);
    const [allServers, setAllServers] = useState([]);


    useEffect(() => {
        GetAllGroupServers(groupId, setServersInGroup);
        GetServers(setAllServers, navigate);
    }, []);

    const filtered = allServers.filter(server => serversInGroup.find(x => x.serverId == server.id) == null && server.serverType == ServerGroup.serverType);
    return(
        <>
            <div className="widgetListContainer" style={{maxHeight: "80%"}}>
                { serversInGroup.map((serverToGroup) => 
                    <ServerGroupServerPanel serverToGroup={serverToGroup} server={allServers.find(x => x.id == serverToGroup.serverId)} setServers={setServersInGroup} />
                )}
                <button onClick={() => setAddServerWindow(true)} className="widgetListMainButton">Add Server <FaPlus style={{marginLeft: "0.25vw"}}/></button>
            </div>
            { addServerWindow &&
                <ModalWindow width={"50%"} closeFunction={() => setAddServerWindow(false)} showCloseButton={true} title={"Add Server"} content={
                    <>
                        { filtered.map(server => 
                            <ServerAddPanel groupId={groupId} server={server} onSuccess={() => GetAllGroupServers(groupId, setServersInGroup)}/>
                        )}
                        { filtered.length == 0 &&
                            <p>There are no servers to add!</p>
                        }
                    </>
                }/> 
            }
        </>
    )
}

export default ServerGroupServerList;