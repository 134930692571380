import { FaSearch } from "react-icons/fa";
import PlayerButton from "./Elements/PlayerButton/PlayerButton";
import RequestData from "./RACategories/RequestData/RequestData";
import RoleManagement from "./RACategories/RoleManagement/RoleManagement";
import Inventory from "./RACategories/Inventory/Inventory";
import Broadcasting from "./RACategories/Broadcasting/Broadcasting";
import { useState } from "react";
import { useEffect } from "react";
import StatusEffects from "./RACategories/StatusEffects/StatusEffects";
import Moderation from "./RACategories/Moderation/Moderation";
import Administration from "./RACategories/Administration/Administration";
import StaffChat from "./RACategories/StaffChat/StaffChat";
import RoundEvents from "./RACategories/RoundEvents/RoundEvents";
import MapControl from "./RACategories/MapControl/MapControl";
import Cassie from "./RACategories/Cassie/Cassie"; 
import { GetAllUsers } from "../../Scripts/Requests/PlayersRequests.js";
import { useParams } from "react-router-dom";
import RaNavigation from "./Elements/RaNavigation/RaNavigation.js";
import playerRoles from "../../Data/PlayerRoles.js";
import { GetServer } from "../../Scripts/Requests/ServerRequests.js";

const RemoteAdmin = () => 
{
    const [change, setChange] = useState(false);
    const [selectedPlayers, SetSelectedPlayers] = useState([]);
    const [selectedPanel, SetSelectedPanel] = useState(0);

    const [server, SetServer] = useState(null);

    const[playerNickSearch, setPlayerNickSearch] = useState("");
    const[sortMethod, setSortMethod] = useState(0);

    const [players, SetPlayers] = useState({ users: [], usersWhoLeft: []});

    const { id } = useParams();
    
    useEffect(() => {}, [selectedPanel, change]);

    useEffect(() => {
      GetServer(id, SetServer);
      GetAllUsers(id, SetPlayers);

      clearInterval(() => GetAllUsers(id, SetPlayers));
      setInterval(() => {
        GetAllUsers(id, SetPlayers);
      }, 10000)
    }, []);

    const ChangePlayerSelection = (player) => {
      
      let data = selectedPlayers;

      const selectedPlayer = data.find(x => x.userId == player.userId);

      if(selectedPlayer) {
        data.splice(data.indexOf(selectedPlayer), 1);   
      } else {
        data.push(player);
      }
      SetSelectedPlayers(data);

      setChange(player.userId + " " + (selectedPlayer != null));
    }

    const SortPlayers = (a, b) => 
    {
      switch (sortMethod) {
        case 0:
          return a.inGameId - b.inGameId;
        case 1:
          return a.nickname > b.nickname;
        case 2:
          return a.playerRoleId - b.playerRoleId;
        case 3:
          return playerRoles[a.playerRoleId + 1].team - playerRoles[b.playerRoleId + 1].team;
        default:
          return a.inGameId - b.inGameId;
      }
      
    }

    const GetPlayersGameIds = () => {
      let data = "";
      selectedPlayers.forEach(player => 
      {
        if(players.users.some(p => p.userId == player.userId))
          data += player.inGameId + ".";
      });
     
      return data;
    }

    const GetPlayersWhoLeft = () => {
      let data = [];
      selectedPlayers.forEach(player => 
      {
        if(players.usersWhoLeft.some(p => p.userId == player.userId))
          data.push(player);
      });

      return data;
    }

    if(server == null)
      return (<></>)
    return(
        <div className="panelField">
          <div className="sidebar">
            <div className="playerListField">
              {
                players.users.sort((a, b) => SortPlayers(a, b)).map((player) => 
                  { if(player.nickname.toLowerCase().includes(playerNickSearch.toLowerCase()))
                    return (
                    <PlayerButton 
                      key={"playerPanel_" + player.userId} 
                      playerData={player} 
                      left={false}            
                      ChangePlayerSelection={ChangePlayerSelection}
                      isSelected={selectedPlayers.find(x => x.userId == player.userId) != null}
                    />)
                  }
                )
              }
              { players.usersWhoLeft.length > 0 &&
                <div style={{display:"flex"}}>
                  <div style={{marginTop:"10px"}} className="titleLine"></div>
                    <label style={{fontSize: "1.25rem", margin: "auto"}}>Players who left:</label>
                  <div style={{marginTop:"10px"}} className="titleLine"></div>
                </div>
              }
              {
                players.usersWhoLeft.sort((a, b) => SortPlayers(a, b)).map((player) => 
                  { if(player.nickname.toLowerCase().includes(playerNickSearch.toLowerCase()))
                    return (
                      <PlayerButton 
                        key={"playerPanel_" + player.userId} 
                        playerData={player} left={false}            
                        ChangePlayerSelection={ChangePlayerSelection}
                        isSelected={selectedPlayers.find(x => x.userId == player.userId) != null}
                      />
                    )
                  }
                )
              }
            </div>
            
            <div className="playerListFilterContainer">
              <select onChange={(e) => {setSortMethod(e.target.options.selectedIndex);}}>
                <option>Player IDs</option>
                <option>Name (Alphabetical)</option>
                <option>Class</option>
                <option>Team</option>
              </select>
            </div>

            <div className="searchPlayerBarContainer">
              <FaSearch style={{color: "gray", marginRight: "0.25vw"}}/>
              <input onChange={(e) => setPlayerNickSearch(e.target.value)} type="text" placeholder="Search player..." style={{width: "100%"}}></input>
            </div>
          </div>
          
        <div className="playerActionContainer">
          {selectedPanel == 0 && <RequestData playerData = {selectedPlayers[0]} />}
          {selectedPanel == 1 && <RoleManagement GetPlayersGameIds = {GetPlayersGameIds} serverId={id}/>}
          {selectedPanel == 2 && <Inventory GetPlayersGameIds={GetPlayersGameIds}/>}
          {selectedPanel == 3 && <StatusEffects />}
          {selectedPanel == 4 && <Moderation GetPlayersGameIds={GetPlayersGameIds} GetPlayersWhoLeft = {GetPlayersWhoLeft}/>}
          {selectedPanel == 5 && <Administration GetPlayersGameIds={GetPlayersGameIds}/>}
          {selectedPanel == 6 && <Broadcasting GetPlayersGameIds={GetPlayersGameIds}/>}
          {selectedPanel == 7 && <StaffChat />}
          {selectedPanel == 8 && <RoundEvents />}
          {selectedPanel == 9 && <MapControl />}
          {selectedPanel == 10 && <Cassie />}
        </div>

        <RaNavigation SetSelectedPanel={SetSelectedPanel} PermissionsInGroup={server.permissions}/>
      </div>
    );
}
export default RemoteAdmin;