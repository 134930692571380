import axios from "axios";
import { Cookies } from "react-cookie";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/ServerGroups/';
const cookies = new Cookies();

const GetAllServerGroups = (SetServerGroups, navigate) => 
{
    axios.get(api_url + "GetAll", {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServerGroups(res.data);
    })
    .catch((error) => 
    {
        console.error(error);
        //navigate("/");
    });
}

const GetServerGroup = (SetServerGroup, Id) => 
{
    axios.get(api_url + "Get/" + Id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServerGroup(res.data);
    })
    .catch((error) => 
    {
        console.error(error);
        //navigate("/");
    });
}

const AddServerGroup = (SetServerGroups, navigate, groupName, serverType, setGroupWindow) => 
{
    const data = {
        Name: groupName,
        ServerType: serverType
    }

    axios.post(api_url + "Add", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetAllServerGroups(SetServerGroups, navigate);
        setGroupWindow(false);
    })
    .catch((error) => 
    {
        console.error(error);
    });
};

const RemoveServerGroup = (id, SetServerGroups, navigate) => 
{
    axios.delete(api_url + "Remove/" + id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetAllServerGroups(SetServerGroups, navigate);
    })
    .catch((error) => 
    {
        console.error(error);
    });
};

export { GetAllServerGroups, GetServerGroup, AddServerGroup, RemoveServerGroup };
