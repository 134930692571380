const BroadcastColors = [
    "red",
    "green",
    "blue",
    "white",
    "cyan",
    "magenta",
    "yellow",
    "orange"
]

export default BroadcastColors;