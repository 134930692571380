const StaffChatMessage = ({messageData}) => 
{
    return (
        <div className="messageBlock">
            <div className="messageTime">[{messageData.time.split("T")[1].split(".")[0]}]</div>
            <div className="messageAuthor">{messageData.nickName} ({messageData.userId}):</div>
            <div className="messageContent">{messageData.message}</div>
        </div>
    );
}
export default StaffChatMessage;