import { useEffect, useState } from "react";
import ServerGroupPanel from "./Elements/ServerGroupPanel";
import { FaPlus } from "react-icons/fa";
import { AddServerGroup, GetAllServerGroups } from "../../../Scripts/Requests/ServerGroupsRequests";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";

const ServerGroupsList = () => {

    const navigate = useNavigate();

    const [addGroupWindow, setAddGroupWindow] = useState(false);
    const [serverGroups, setServerGroups] = useState([]);

    const [groupName, setGroupName] = useState("");
    const [serverType, setServerType] = useState(0);

    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        GetAllServerGroups(setServerGroups, navigate);
    }, [])

    const AddGroup = () => {
        if(groupName.length > 2) {
            AddServerGroup(setServerGroups, navigate, groupName, serverType, setAddGroupWindow);
        }
        else{
            setErrorText("Group name must contain at least 3 characters!");
        }
    }
    
    return (
        <div className="widgetListContainer" style={{maxHeight: "80%"}}>
            { serverGroups.map(group => 
                <ServerGroupPanel serverGroup={group} SetServerGroups={setServerGroups}/>
            )}
            <button onClick={() => {setGroupName(""); setServerType(0); setAddGroupWindow(true);} } className="widgetListMainButton">Add Group <FaPlus style={{marginLeft: "0.25vw"}}/></button>
            
            { addGroupWindow &&
                <ModalWindow title={"Add Group"} width={"50%"} closeFunction={() => { setAddGroupWindow(false); setErrorText(false); } } showCloseButton={true} content={
                    <>
                        <label>Group Name</label>
                        <input onChange={(e) => setGroupName(e.target.value)} className="inputStyle" type="text" style={{width: "98%"}}/>
                        <label>Server Type</label>
                        <select className="inputStyle" onChange={(e) => setServerType(e.target.options.selectedIndex) } >
                            <option>SCP: Secret Laboratory</option>
                            <option>Unturned</option>
                        </select>

                        { errorText.length > 0 &&
                            <div className="errorBox" style={{width: "98%", padding:"5px 0px"} }>{errorText}</div>
                        }

                        <button style={{width: "98%"}} onClick={() => AddGroup()}>Add Group</button>
                    </>
                }/>
            }
            
        </div>
    );
}

export default ServerGroupsList;