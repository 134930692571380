import "./Administration.css";
import { FaRunning, FaWalking, FaHeart, FaCreditCard, FaVolumeDown, FaVolumeMute, FaVolumeUp, FaSyringe } from "react-icons/fa";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests.js";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { AddInfoBoxMessage } from "../../../../Scripts/InfoBoxManager.js";
// import { ImArrowUp, ImArrowDown } from "react-icons/im";

const Administration = ({GetPlayersGameIds}) => 
{
    const { id } = useParams();
    const navigate = useNavigate;

    const [playerHP, setPlayerHP] = useState(null);

    const SetPlayerHPCommand = () => 
    {
        if(playerHP) {
            ExecuteCommandWithPlayer("/sethp ", playerHP);
        }
        else{
            AddInfoBoxMessage("You must enter the heal amount!", 0);
        }
    }

    const ExecuteCommandWithPlayer = (commandToExecute, commandEnding) => {
        const playerIds = GetPlayersGameIds();

        if(commandEnding == null)
            return;

        if(playerIds.length > 1)
        {
            const command = commandToExecute + playerIds + " " + commandEnding;
            ExecuteCommand(id, command);
        }
        else{
            AddInfoBoxMessage("You must select atleast one player to execute this command", 0);
        }
    }

    return(
        <div className="optionsPanelContainer">
            
            <div className="optionsPaneldescription">
                Select players from the list, then utilize any of the tools below.
            </div>

            <div className="leftContainer">
                <div className="positioningTools">
                    <div className="toolsDesc">Positioning Tools</div>
                    <div className="toolsContainer" style={{gridTemplateColumns:"repeat(2, 5.5vw)"}}>
                        {/* <div className="toolButton">
                            <button className="squareBtn goTo"><ImArrowUp size="2vw" style={{color:"#B977F2"}}/></button>
                            Go To
                        </div>
                        <div className="toolButton">
                            <button className="squareBtn bring"><ImArrowDown size="2vw" style={{color:"#FF9B59"}}/></button>
                            Bring
                        </div> */}
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/noclip ", "enable")} className="squareBtn noclipOn"><FaRunning size="2vw" style={{color:"#43F15F"}}/></button>
                            Noclip
                            <div className="toolStatus" style={{backgroundColor:"green"}}>ON</div>
                        </div>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/noclip ", "disable")} className="squareBtn noclipOff"><FaWalking size="2vw" style={{color:"#FF7F7F"}}/></button>
                            Noclip
                            <div className="toolStatus" style={{backgroundColor:"rgba(255, 0, 0, 0.625)"}}>OFF</div>
                        </div>
                    </div>
                </div>

                <div className="intercomTools">
                    <div className="toolsDesc">Intercom</div>
                    <div className="toolsContainer" style={{gridTemplateColumns:"repeat(4, 5.5vw)"}}>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommand(id, "/icomtimeout")} className="squareBtn goTo"><FaVolumeMute size="2vw" style={{color:"#FF7F7F"}}/></button>
                            Timeout
                        </div>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommand(id, "/icomreset")} className="squareBtn bring"><FaVolumeDown size="2vw" style={{color:"#43F15F"}}/></button>
                            Reset
                        </div>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/icom ", "1")} className="squareBtn noclipOn"><FaVolumeUp size="2vw" style={{color:"#B977F2"}}/></button>
                            Global Intercom
                            <div className="toolStatus" style={{backgroundColor:"green"}}>ON</div>
                        </div>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/icom ", "0")} className="squareBtn noclipOff"><FaVolumeUp size="2vw" style={{color:"#FF4747"}}/></button>
                            Global Intercom
                            <div className="toolStatus" style={{backgroundColor:"rgba(255, 0, 0, 0.625)"}}>OFF</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rightContainer">
                <div className="godmodeTools">
                    <div className="toolsDesc">Godmode</div>
                    <div className="toolsContainer" style={{gridTemplateColumns:"repeat(2, 5.5vw)"}}>
                        <div className="toolButton">
                                <button onClick={() => ExecuteCommandWithPlayer("/god ", "1")}  className="squareBtn bring"><FaHeart size="2vw" style={{color:"#43F15F"}}/></button>
                                <div className="toolStatus" style={{backgroundColor:"green"}}>ON</div>
                        </div>
                        <div className="toolButton">
                                <button onClick={() => ExecuteCommandWithPlayer("/god ", "0")} className="squareBtn bring"><FaHeart size="2vw" style={{color:"#FF7F7F"}}/></button>
                                <div className="toolStatus" style={{backgroundColor:"rgba(255, 0, 0, 0.625)"}}>OFF</div>
                        </div>
                    </div>
                </div>

                <div className="bypassTools">
                    <div className="toolsDesc">Bypass</div>
                    <div className="toolsContainer" style={{gridTemplateColumns:"repeat(2, 5.5vw)"}}>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/bypass ", "1")} className="squareBtn bring"><FaCreditCard size="2vw" style={{color:"#43F15F"}}/></button>
                            <div className="toolStatus" style={{backgroundColor:"green"}}>ON</div>
                        </div>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/bypass ", "0")} className="squareBtn bring"><FaCreditCard size="2vw" style={{color:"#FF7F7F"}}/></button>
                            <div className="toolStatus" style={{backgroundColor:"rgba(255, 0, 0, 0.625)"}}>OFF</div>
                        </div>
                    </div>
                </div>

                <div className="healthStatusTools">
                    <div className="toolsDesc">Health Status</div>
                        <div className="TxtBoxContainer">
                            <input onChange={e => setPlayerHP(e.target.value)} type="text" className="TxtBox" placeholder="Health amount"></input>
                        </div>
                    <div className="toolsContainer" style={{gridTemplateColumns:"repeat(2, 5.5vw)"}}>
                        <div className="toolButton">
                            <button onClick={() => ExecuteCommandWithPlayer("/heal ", "")} className="squareBtn bring"><FaSyringe size="2vw" style={{color:"#FF96D5"}}/></button>
                            Heal
                        </div>
                        <div className="toolButton">
                            <button onClick={() => SetPlayerHPCommand()} className="squareBtn bring"><FaSyringe size="2vw" style={{color:"#ADE04E"}}/></button>
                            Set HP
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Administration;