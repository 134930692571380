import { FaServer } from "react-icons/fa";

const ServerMainLog = ({content}) =>{
    return (
        <div style={{display: "flex"}}>
            <FaServer size={"30px"} color="rgb(61, 201, 220)"/>
            <div style={{marginLeft: "20px", fontSize: "22px"}}>{content}</div>
        </div>
    )
}

export default ServerMainLog;