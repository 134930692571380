const SCPPermissions = {
    SCP_UserData : 1,
    SCP_SensitiveUserData : 2,
    SCP_RoleManagement : 4,
    SCP_Inventory : 8,
    SCP_StatusEffects : 16,
    SCP_Kick : 32,
    SCP_Ban : 64,
    SCP_Mutes : 128,
    SCP_Administration : 256,
    SCP_Broadcasting : 512,
    SCP_StaffChat : 1024,
    SCP_RoundEvents : 2048,
    SCP_RespawnEvents : 4096,       
    SCP_MapControl : 8192,
    SCP_Cassie : 16384,
    SCP_ServerConsoleCommands : 32768
}

const SCPPermissionsNames = [
    { Enum: "SCP_UserData",  Name: "SCP User Data" },
    { Enum: "SCP_SensitiveUserData",  Name: "SCP Sensitive User Data" },
    { Enum: "SCP_RoleManagement",  Name: "SCP RoleManagement" },
    { Enum: "SCP_Inventory",  Name: "SCP Inventory" },
    { Enum: "SCP_StatusEffects",  Name: "SCP Status Effects" },
    { Enum: "SCP_Kick",  Name: "SCP Kick" },
    { Enum: "SCP_Ban",  Name: "SCP Ban" },
    { Enum: "SCP_Mutes",  Name: "SCP Mutes" },
    { Enum: "SCP_Administration",  Name: "SCP Administration" },
    { Enum: "SCP_Broadcasting",  Name: "SCP Broadcasting" },
    { Enum: "SCP_StaffChat",  Name: "SCP StaffChat" },
    { Enum: "SCP_RoundEvents",  Name: "SCP RoundEvents" },
    { Enum: "SCP_RespawnEvents",  Name: "SCP RespawnEvents" },
    { Enum: "SCP_MapControl",  Name: "SCP MapControl" },
    { Enum: "SCP_Cassie",  Name: "SCP Cassie" },
    { Enum: "SCP_ServerConsoleCommands",  Name: "SCP ServerConsoleCommands" },
]

export { SCPPermissions, SCPPermissionsNames };