import { useState } from "react";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";
import "./ServerPanel.css";
import { FaTrash  } from "react-icons/fa";
import { CheckPermission } from "../../../Scripts/PermissionsManager";
import { jwtDecode } from "jwt-decode";
import { Permissions } from "../../../Data/Permissions";
import { Cookies } from "react-cookie";
import { ServerTypes } from "../../../Data/ServerTypes";
import { useNavigate } from "react-router-dom";

const ServerPanel = ({server, removeServer}) => {
    const navigate = useNavigate();

    const [removeServerWindow, setRemoveServerWindow] = useState(false);

    const cookies = new Cookies();

    const splittedName = server.name.split("<color=#00000000>")[0];
    const serverName = splittedName
    .replaceAll("<color=", "<span style='color:")  
    .replaceAll(">", "''>")
    .replaceAll("</color''}>", "</span>")
    .replaceAll("b''>", "b>");

    return(
    <div className="ServerPanel">
        <div className="listWidget">    
            <div className="serverPanelConatiner">
                <table style={{fontSize: "1.125rem", margin: "0.5em 0.5em 0 0.5em", width: "90%"}}>
                    <tr style={{fontSize: "1.5rem"}}>
                        <td className="infoCell">Server Name: </td>
                        <td className="dataCell" dangerouslySetInnerHTML={{__html: serverName}}></td>
                    </tr>
                    <tr>
                        <td className="infoCell">Server Type:</td>
                        <td className="dataCell">{ServerTypes.find(x => x.id == server.serverType).name}</td>
                    </tr>  
                    {server.ip != "none" &&
                        <tr>
                            <td className="infoCell">Server Address:</td>
                            <td className="dataCell">{server.ip}:{server.port}</td>
                        </tr>               
                    }

                    {server.isActive &&
                        <tr>
                            <td className="infoCell">Players: </td>
                            <td className="dataCell">{server.playerCount} / {server.maxPlayerCount}</td>
                        </tr>
                    }

                </table>
                { CheckPermission(jwtDecode(cookies.get("jwt")).Permissions, Permissions.Admin, true) && 
                    <FaTrash className="deleteServerIcon iconButton" size="2.5vh" onClick={() => setRemoveServerWindow(true)}/>
                }
            </div>

            {!server.isActive &&
                <div className="serverStatus serverStatusOffline">OFFLINE</div>
            }
            {server.isActive &&
                <>
                    <div style={{width: "100%", display: "flex"}}>
                        <button onClick={() => {navigate("/remote/"+ server.id)}} className="serverOptionsButton">Remote Admin</button>
                        <button onClick={() => {navigate("/management/"+ server.id)}} className="serverOptionsButton">Server Management</button>
                        <button onClick={() => {navigate("/logs/"+ server.id)}} className="serverOptionsButton">Server Logs</button>
                    </div>

                    <div className="serverStatus serverStatusOnline">ONLINE</div>
                </>
            }
            
            { removeServerWindow != "" &&
                <ModalWindow title={"Remove server?"} width={"30%"} content=
                {
                    <>
                        <p>This action can't be undone</p>
                        <div style={{display:"flex"}}>
                            <button style={{width:"50%"}} onClick={() => { removeServer(server.id); setRemoveServerWindow(false) }}>Remove</button>
                            <button style={{width:"50%"}} onClick={() => { setRemoveServerWindow(false) }}>Cancel</button>
                        </div>
                    </>
                }/>
            }
        </div>
    </div>)
}
export default ServerPanel;