import { IoMdClose } from "react-icons/io";
import "./InfoBox.css";
import { RemoveInfoBoxMessage } from "../../../Scripts/InfoBoxManager";

const InfoBox = ({text, type, index}) => {

    const boxClass = type == 0 ? "infoBoxError" : "infoBoxSuccess";

    return (       
        <div className={'infoBox ' + boxClass}>
            <div className='infoBoxText'>{text}</div>
            <div className='infoBoxCloseButtonContainer'>
                <IoMdClose onClick={() => RemoveInfoBoxMessage(index)} className='infoBoxCloseButton' size={"25px"} />
            </div>
        </div>
    )
}

export default InfoBox;