import "./LogElement.css";

const LogElement = ({logData}) => {
    const time = logData.creationDate.split("T");
    return (
        <>
            <div className="listWidget" style={{marginBottom:"5px"}}>
                <div className="logPanel">
                    <span className="logTimeText">[{time[0]} {time[1].split(".")[0]}]</span> <span className="logTypeText">({logData.logType})</span> 
                    <div dangerouslySetInnerHTML={{__html: logData.content}}></div>
                </div>
            </div>
        </>
    )
}

export default LogElement;