import { useState } from "react";
import { RemoveServerFromGroup } from "../../../Scripts/Requests/ServerGroupServersRequest";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";

const ServerGroupServerPanel = ({serverToGroup, server, setServers}) => {

    const [removeWindow, setRemoveWindow] = useState(false);

    if(server == null)
        return (<></>)

    const splittedName = server.name.split("<color=#00000000>")[0];
    const serverName = splittedName
    .replaceAll("<color=", "<span style='color:")  
    .replaceAll(">", "''>")
    .replaceAll("</color''}>", "</span>")
    .replaceAll("b''>", "b>");

    return(
        <>
            <div className="listWidget" style={{paddingLeft: "10px"}}>
                <div style={{display: "flex"}}>
                    <div style={{fontSize:"20px", marginTop:"12px", width:"89%"}} dangerouslySetInnerHTML={{__html: serverName}}></div>
                    <button onClick={() => setRemoveWindow(true)}>Remove from group</button>
                </div>
                  
            </div>
            { removeWindow &&
                <ModalWindow width={"50%"} title={"Remove Server From Group"}
                    content={
                        <>
                            <p>This action can't be undone</p>
                            <div style={{display:"flex"}}>
                                <button style={{width:"50%"}} onClick={() => { RemoveServerFromGroup(serverToGroup.id, serverToGroup.serverGroupId, setServers); setRemoveWindow(false) }}>Remove</button>
                                <button style={{width:"50%"}} onClick={() => { setRemoveWindow(false) }}>Cancel</button>
                            </div>
                        </>
                    }
                />
            }
        </>
    )
}

export default ServerGroupServerPanel;