import { FaExchangeAlt } from "react-icons/fa";
import playerRoles from "../../../Data/PlayerRoles.js";

const PlayerChangedRoleLog = ({content}) => {
    const splitted = content.split(";");

    const oldRole = playerRoles.find(x => x.id == splitted[3]);
    const newRole = playerRoles.find(x => x.id == splitted[4]);

    return (
        <>
            <div>
                <div style={{ display: "flex" }}> 
                    <FaExchangeAlt size={"30px"}/>
                    <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px"}}>Player's role changed</h2>
                </div>
                <div style={{ marginLeft:"30px" }}>
                    <div>Nick: {splitted[2]}</div>
                    <div>Player Id: {splitted[0]}</div>
                    <div>User Id: {splitted[1]} </div>
                    { oldRole &&
                        <div>Old role: <span style={{color: oldRole.color}}>{oldRole.name}</span></div>
                    }
                    { newRole &&
                        <div>New role: <span style={{color: newRole.color}}>{newRole.name}</span></div>
                    }
                    
                </div>
            </div>
        </>
    )
}

export default PlayerChangedRoleLog;