import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetUser } from "../../../Scripts/Requests/UsersRequests";
import { FaPen, FaTrash } from "react-icons/fa";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";
import { EditUserInGroup, RemoveUserFromGroup } from "../../../Scripts/Requests/ServerGroupUsersRequests";
import { SCPPermissions, SCPPermissionsNames } from "../../../Data/SCP/SCPPermissions";
import { GetPermissionsToDisplay } from "../../../Scripts/PermissionsManager";

const ServerGroupUserPanel = ({userToGroup, SetUsers}) => {

    const navigate = useNavigate();

    const [editWindow, setEditWindow] = useState(false);
    const [removeWindow, setRemoveWindow] = useState(false);

    const [user, setUser] = useState({});

    useEffect(() => {
        GetUser(userToGroup.userId, setUser);
    }, []);


    let DefaultArray = []

    SCPPermissionsNames.map(permissionName => 
        {
            if(userToGroup.permissions & SCPPermissions[permissionName.Enum]) 
                DefaultArray.push(SCPPermissions[permissionName.Enum])
        }
    )

    const [userPermissionsArray, setUserPermissionsArray] = useState(DefaultArray);
    const [userPermissions, setUserPermissions] = useState(0);

    function OnPermissionCheckedChange(isChecked, enumNumber) {
        let arrayToEdit = userPermissionsArray;
        
        if(isChecked)
            arrayToEdit.push(SCPPermissions[enumNumber]);
        else
            arrayToEdit.splice(arrayToEdit.indexOf(SCPPermissions[enumNumber]), 1);

        setUserPermissionsArray(arrayToEdit);

        let permissionNumber = 0;
        userPermissionsArray.forEach((permission) => permissionNumber += permission);

        setUserPermissions(permissionNumber)
    }

    return (
        <div className="listWidget">
            <div style={{display: "flex"}}>
                <div className="profileDataTableContainer tableStyles">
                    <table style={{fontSize: "1.125rem", margin: "0.5em 0.5em 0 0.5em", width: "50%"}}>
                        <tr>
                            { user.steamPlayer &&
                                <div className="steamProfileImageContainer">
                                    <img alt="steam profile icon" className="steamProfileImage" src={user.steamPlayer.avatarfull} />
                                </div>
                            }
      
                        </tr>
                        { user.panelUser &&
                            <tr>
                                <td className="infoCell">Login:</td>
                                <td className="dataCell">{user.panelUser.login}</td>
                            </tr>  
                        }
                    </table>
                    <div className="userButtons">
                        <button onClick={() => { setEditWindow(true) }}> <FaPen style={{marginRight: "0.4vw"}} /> Edit Permissions</button>
                        <button onClick={() => { setRemoveWindow(true) }}> <FaTrash style={{marginRight: "0.4vw"}}/> Remove From Group</button>
                    </div>
                </div>
                <div style={{marginTop: "20px", width: "50%", marginRight:"40px"}}>
                    <h3 style={{textAlign:"center"}}>Permissions</h3>
                    <div className="accountListContainer" style={{ width:"100%", height: "auto", minHeight: "150px"}}>
                        <GetPermissionsToDisplay permissions={userToGroup.permissions} permissionsNames={SCPPermissionsNames} permissionsEnum={SCPPermissions}/>
                    </div>
                </div>
            </div>
            { removeWindow &&
                <ModalWindow width={"50%"} title={"Remove User From Group "}
                    content={
                        <>
                            <p>This action can't be undone</p>
                            <div style={{display:"flex"}}>
                                <button style={{width:"50%"}} onClick={() => { RemoveUserFromGroup(userToGroup.id, userToGroup.serverGroupId, SetUsers); setRemoveWindow(false) }}>Remove</button>
                                <button style={{width:"50%"}} onClick={() => { setRemoveWindow(false) }}>Cancel</button>
                            </div>
                        </>
                    }
                />
            }
            { editWindow &&
                <ModalWindow width={"60%"} title={"Edit User Permissions"}
                    content={
                        <>
                            <div style={{marginBottom:"20px"}}>
                                {
                                    SCPPermissionsNames.map(permissionName => 
                                        { 
                                            if(permissionName.Enum != "Root")
                                            return(
                                                <div style={{display: "inline-block", width:"25%"}}>
                                                    <input defaultChecked={userToGroup.permissions & SCPPermissions[permissionName.Enum]} onChange={e => OnPermissionCheckedChange(e.target.checked, permissionName.Enum )} type="checkbox" />
                                                    <label>{permissionName.Name}</label>
                                                </div>
                                            )
                                        }                              
                                    )
                                }
                            </div>
                            <div style={{display:"flex"}}>
                                <button style={{width:"50%"}} onClick={() => { EditUserInGroup(userToGroup.id, userPermissions, userToGroup.serverGroupId, SetUsers); setEditWindow(false); }}>Edit</button>
                                <button style={{width:"50%"}} onClick={() => { setEditWindow(false) }}>Cancel</button>
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}

export default ServerGroupUserPanel;