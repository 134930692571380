const Bans = [
    { Text: "Kick", Time: "0" },
    { Text: "1 min", Time: "1" },
    { Text: "5 min", Time: "5" },
    { Text: "15 min", Time: "15" },
    { Text: "30 min", Time: "30" },
    { Text: "1 h", Time: "60" },
    { Text: "3 h", Time: "180" },
    { Text: "5 h", Time: "300" },
    { Text: "8 h", Time: "480" },
    { Text: "12 h", Time: "720" },
    { Text: "1 d", Time: "1440" },
    { Text: "3 d", Time: "7200" },
    { Text: "7 d", Time: "10080" },
    { Text: "14 d", Time: "20160" },
    { Text: "30 d", Time: "43200" },
    { Text: "100 d", Time: "144000" },
    { Text: "1 y", Time: "525600" },
    { Text: "50 y", Time: "26280000" },
]
export default Bans;