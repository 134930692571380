import { useState } from "react";
import GroupButtons from "../../../Elements/GroupButtons/GroupButtons";
import "../Administration/Administration.css";
import { FaRegClock, FaRegPauseCircle, FaLock, FaLockOpen, FaPlay, FaBomb } from "react-icons/fa";
import { FaB, FaExplosion } from "react-icons/fa6";
import { MdRestartAlt } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { TbSwords } from "react-icons/tb";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests";
import { useNavigate, useParams } from "react-router-dom";

const RoundEvents = () => 
{
    const { id } = useParams();

    const [selectedCategory, setSelectedCategory] = useState(0);
    
    return (
        <div className="optionsPanelContainer">
            <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "STATES & EVENTS"}, {text: "TOKEN SYSTEM"}]}/>
            {selectedCategory == 0 &&
                <div>
                    <div className="subpageDescription">
                        Round-affecting commands and configurations.
                    </div>

                    <div className="leftContainer">
                    
                        <div className="toolsDesc">Alpha Warhead</div>
                            <div className="toolsContainer">
                                <div className="toolButton">
                                    <button onClick={() => ExecuteCommand(id, "/server_event detonation_start")} className="squareBtn">
                                        <FaRegClock size="2vw" color="#ffe066"/>
                                    </button>
                                    Start
                                </div>
                                <div className="toolButton">
                                    <button onClick={() => ExecuteCommand(id, "/server_event detonation_cancel")} className="squareBtn">
                                        <FaRegPauseCircle size="2vw" color="#99ccff"/>
                                    </button>
                                    Stop
                                </div>
                                {/* <div className="toolButton">
                                    <button onClick={() => ExecuteCommand(id, "/warhead lock")}  className="squareBtn">
                                        <FaLock size="2vw" color="#FF7F7F"/>
                                    </button>
                                    Lock
                                    <div className="toolStatus" style={{backgroundColor: "rgba(255, 0, 0, 0.625"}}>OFF</div>
                                </div> */}
                                <div onClick={() => ExecuteCommand(id, "/server_event detonation_instant")} className="toolButton">
                                    <button className="squareBtn">
                                        <FaExplosion size="2vw" color="#FF9900"/>
                                    </button>
                                    Boom
                                </div>
                            </div>

                        <div className="toolsDesc">Round & Lobby</div>
                            <div className="toolsContainer">
                                <div className="toolButton">
                                <button onClick={() => ExecuteCommand(id, "/forcestart")} className="squareBtn">
                                        <FaPlay size="2vw" color="#43F15F"/>
                                    </button>
                                    Play
                                </div>
                                <div className="toolButton">
                                    <button onClick={() => ExecuteCommand(id, "/roundrestart")} className="squareBtn"> 
                                        <MdRestartAlt size="2vw" color="#99ccff"/>
                                    </button>
                                    Restart
                                </div>
                                {/* <div className="toolButton">
                                    <button className="squareBtn">
                                        <FaLock size="2vw" color="#FF7F7F"/>
                                    </button>
                                    Round Lock
                                    <div className="toolStatus" style={{backgroundColor: "rgba(255, 0, 0, 0.625"}}>OFF</div>
                                </div>
                                <div className="toolButton">
                                    <button className="squareBtn">
                                        <FaLock size="2vw" color="#FF7F7F"/>
                                    </button>
                                    Lobby Lock
                                    <div className="toolStatus" style={{backgroundColor: "rgba(255, 0, 0, 0.625"}}>OFF</div>
                                </div> */}
                            </div>
                    </div>

                    <div className="rightContainer">
                        <div className="toolsDesc">Decontamination</div>
                            <div className="toolsContainer" style={{gridTemplateColumns: "repeat(3, 5.5vw)"}}>
                                    <div className="toolButton">
                                        <button onClick={() => ExecuteCommand(id, "/decontamination enable")} className="squareBtn">
                                            <label style={{fontSize: "1.5rem", margin: 0, padding: 0, color: "#43F15F"}}>ON</label>
                                        </button>
                                        Enable
                                    </div>
                                    <div className="toolButton">
                                        <button onClick={() => ExecuteCommand(id, "/decontamination disable")} className="squareBtn">
                                            <label style={{fontSize: "1.5rem", margin: 0, padding: 0, color: "#FF7F7F"}}>OFF</label>
                                        </button>
                                        Disable
                                    </div>
                                    <div className="toolButton">
                                        <button onClick={() => ExecuteCommand(id, "/decontamination force")} className="squareBtn">
                                            <IoWarningOutline size="2vw" color="#43F15F"/>
                                        </button>
                                        Force
                                    </div>
                                </div>

                        <div className="toolsDesc">Gameplay</div>
                            <div className="toolsContainer" style={{gridTemplateColumns: "repeat(2, 5.5vw)"}}>
                                    <div className="toolButton">
                                        <button className="squareBtn">
                                            <TbSwords size="2vw" color="#43F15F"/>
                                        </button>
                                        Enable
                                        <div className="toolStatus" style={{backgroundColor: "green"}}>ON</div>
                                    </div>
                                    <div className="toolButton">
                                        <button className="squareBtn">
                                            <FaBomb size="2vw" color="#43F15F"/>
                                        </button>
                                        Spawn Protection
                                        <div className="toolStatus" style={{backgroundColor: "green"}}>ON</div>
                                    </div>
                                </div>
                    </div>
                </div>
            }
            {selectedCategory == 1 &&
                <div>
                    <div className="subpageDescription">
                        Round-affecting commands and configurations.
                    </div>
                </div>
            }
            
        </div>
    );
}
export default RoundEvents;