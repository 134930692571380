import "./Broadcasting.css"
import "../Administration/Administration.css";
import { useState } from "react";
import { AddInfoBoxMessage } from "../../../../Scripts/InfoBoxManager";
import broadcastTime from "../../../../Data/SCP/BroadcastTime.js";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests.js";
import { useParams } from "react-router-dom";
import BroadcastColors from "../../../../Data/SCP/BroadcastColors.js";

const Broadcasting = ({GetPlayersGameIds}) => {

    const { id } = useParams();

    const [selectedTime, setSelectedTime] = useState(-1);
    const [selectedColor, setSelectedColor] = useState(-1);
    const [boldSelected,  setBoldSelected] = useState(false);
    const [italicSelected,  setItalicSelected] = useState(false);
    const [underlineSelected,  setUnderlineSelected] = useState(false);
    const [lineThroughSelected,  setLineThroughSelected] = useState(false);

    const [message, setMessage] = useState("");

    const getColorButtonStyle = (color, buttonNum) => {
        if(selectedColor == buttonNum)
            return {color: "black", backgroundColor: color, borderColor: color}

        return {color: color, borderColor: color}
    }

    const CheckStyleButton = (defaultStyle, selected) => {
        if(selected) 
        {
            defaultStyle.backgroundColor = "white";
            defaultStyle.color = "black";
        }

        return defaultStyle;
    }

    const SendBroadcast = (toAll) => 
    {
        if(selectedTime == -1)
        {
            AddInfoBoxMessage("You must select the time before sending this broadcast!", 0);
            return;
        }

        let messageToSend = message;

        if(boldSelected)
            messageToSend = `<b>${messageToSend}`

        if(italicSelected)
            messageToSend = `<i>${messageToSend}`        

        if(underlineSelected)
            messageToSend = `<u>${messageToSend}`  

        if(lineThroughSelected)
            messageToSend = `<s>${messageToSend}`  

        if(selectedColor != -1)
            messageToSend = `<color=${BroadcastColors[selectedColor]}>${messageToSend}`        


        if(toAll)
        {
            ExecuteCommand(id, `/bc ${broadcastTime[selectedTime]} ${messageToSend}`)
        }else {
            ExecuteCommand(id, `/pbc ${GetPlayersGameIds()} ${broadcastTime[selectedTime]} ${messageToSend}`)
        }
    }
    
    const getTimeButtonStyle = (buttonId) => {
        if(buttonId == selectedTime)
            return { backgroundColor:"white", color:"black" };
    }

    return(
        <div className="optionsPanelContainer broadcastingContainer">
            
            <div className="optionsPaneldescription">
                Write a message and it will appear at the top of the screen.
            </div>

            <div className="topContainer">
                <div className="topLeftContainer">
                    <div className="toolsContainer" style={{gridTemplateColumns: "repeat(6, 4.5vw)", gridGap:"0.375vw"}}>
                       <button style={getTimeButtonStyle(0)} onClick={() => setSelectedTime(0)} className="squareBtn">1 s</button>
                       <button style={getTimeButtonStyle(1)} onClick={() => setSelectedTime(1)} className="squareBtn">5 s</button>
                       <button style={getTimeButtonStyle(2)} onClick={() => setSelectedTime(2)} className="squareBtn">10 s</button>
                       <button style={getTimeButtonStyle(3)} onClick={() => setSelectedTime(3)} className="squareBtn">15 s</button>
                       <button style={getTimeButtonStyle(4)} onClick={() => setSelectedTime(4)} className="squareBtn">20 s</button>
                       <button style={getTimeButtonStyle(5)} onClick={() => setSelectedTime(5)} className="squareBtn">25 s</button>
                       <button style={getTimeButtonStyle(6)} onClick={() => setSelectedTime(6)} className="squareBtn">30 s</button>
                       <button style={getTimeButtonStyle(7)} onClick={() => setSelectedTime(7)} className="squareBtn">45 s</button>
                       <button style={getTimeButtonStyle(8)} onClick={() => setSelectedTime(8)} className="squareBtn">1 m</button>
                       <button style={getTimeButtonStyle(9)} onClick={() => setSelectedTime(9)} className="squareBtn">2 m</button>
                       <button style={getTimeButtonStyle(10)} onClick={() => setSelectedTime(10)} className="squareBtn">4 m</button>
                       <button style={getTimeButtonStyle(11)} onClick={() => setSelectedTime(11)} className="squareBtn">5 m</button>
                    </div>
                    <div className="clearBtnContainer">
                        <button onClick={() => setMessage("")}>Clear Input</button>
                        {/* TODO:  Clear Broadcasts*/}
                        <button>Clear Broadcasts</button>
                    </div>
                </div>

                <div className="topRightContainer">
                    <div className="toolsContainer bcTextDecoration" style={{gridTemplateColumns: "repeat(4, 4.5vw)", gridGap:"0.375vw"}}>
                       <button className="squareBtn" onClick={() => setSelectedColor(0)} style={getColorButtonStyle("red", 0)}>R</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(1)} style={getColorButtonStyle("forestgreen", 1)}>G</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(2)} style={getColorButtonStyle("blue", 2)}>B</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(3)} style={getColorButtonStyle("white", 3)}>W</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(4)} style={getColorButtonStyle("cyan", 4)}>C</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(5)} style={getColorButtonStyle("magenta", 5)}>M</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(6)} style={getColorButtonStyle("yellow", 6)}>Y</button>
                       <button className="squareBtn" onClick={() => setSelectedColor(7)} style={getColorButtonStyle("orange", 7)}>O</button>
                       <button className="squareBtn" onClick={() => setBoldSelected(!boldSelected)} style={CheckStyleButton({fontWeight:"900"}, boldSelected)}>B</button>
                       <button className="squareBtn" onClick={() => setItalicSelected(!italicSelected)} style={CheckStyleButton({fontStyle:"italic"}, italicSelected)}>I</button>
                       <button className="squareBtn" onClick={() => setUnderlineSelected(!underlineSelected)} style={CheckStyleButton({textDecoration:"underline"}, underlineSelected)}>U</button>
                       <button className="squareBtn" onClick={() => setLineThroughSelected(!lineThroughSelected)} style={CheckStyleButton({textDecoration:"line-through"}, lineThroughSelected)}>S</button>
                    </div>
                </div>
            </div>

            <div className="bottomContainer">
                <textarea onChange={(e) => setMessage(e.target.value)} value={message} rows={4}></textarea>
            </div>

            <div className="bcButtonContainer"> 
                <button onClick={() => SendBroadcast(false)}>Send Privately</button>
                <button onClick={() => SendBroadcast(true)}>Send To All</button>
            </div>

        </div>
    );
}
export default Broadcasting;