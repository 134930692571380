import { FaSkull } from "react-icons/fa";
import playerRoles from "../../../Data/PlayerRoles";

const PlayerKilledLog = ({content}) => {
    const splitted = content.split(";");

    const role = playerRoles.find(x => x.id == splitted[3]);

    const killerRole = playerRoles.find(x => x.id == splitted[9]);

    return (
        <>
            <div>
                <div style={{display: "flex" }}>
                    { (splitted[5] == "0" && splitted[7] != splitted[1]) &&
                        <>
                            <FaSkull size={"30px"} />
                            <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Kill</h2>
                        </>
                    }
                    { splitted[5] == "1" &&
                        <>
                            <FaSkull size={"30px"} color="red"/>
                            <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px", color:"red" }}>Teamkill</h2>
                        </>
                        
                    }
                    { splitted[5] == "2" &&
                        <>
                            <FaSkull size={"30px"} />
                            <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Cuffed Killed</h2>
                        </>
                    }
                    { splitted.length < 6 &&
                        <>
                            <FaSkull size={"30px"} />
                            <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Death</h2>
                        </>
                    }

                    { (splitted.length > 6 && splitted[7] == splitted[1]) &&
                        <>
                            <FaSkull size={"30px"} />
                            <h2 style={{marginLeft:"20px", marginBottom:"5px", marginTop:"0px" }}>Suicide</h2>
                        </>
                    }
                </div>

                    
                <div style={{marginLeft:"20px", display: "flex", marginTop:"10px" }}>
                    <div>
                        <h3 style={{marginBottom:"0px", marginTop:"0px" }}>Victim:</h3>
                        <div>Nick: {splitted[2]}</div>
                        <div>Player Id: {splitted[0]}</div>
                        <div>User Id: {splitted[1]}</div>
                        { role &&
                            <div>Role: <span style={{color: role.color}}>{role.name}</span></div>
                        }
                        
                    </div>

                    { (splitted.length >= 6 && splitted[7] != splitted[1]) &&
                        <div style={{marginLeft:"30px" }}>
                            <h3 style={{marginBottom:"0px", marginTop:"0px" }}>Killer:</h3>
                            <div>Nick: {splitted[8]}</div>
                            <div>User Id: {splitted[6]}</div>
                            <div>Player Id: {splitted[7]} </div>
                            { killerRole &&
                                <div>Role: <span style={{color: killerRole.color}}>{killerRole.name}</span></div>
                            }
                        </div> 
                    }
                              
                </div>
                <h3 style={{marginLeft:"20px", display: "flex", marginTop:"10px" }}>Reason: {splitted[4]}</h3>    
            </div>
        </>
    )
}

export default PlayerKilledLog;